/* ------------------------------------ *\
    INPUT GROUP
\* ------------------------------------ */

.input-group > .input-group__member {
    border-radius: 0;

    &:not(:last-child) { border-right: 0; }

    &:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }

    &:last-child {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}

;@import "sass-embedded-legacy-load-done:331";