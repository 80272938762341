.o-cart__sticky-checkout {
    background: white;
    padding: $space * 2;
    box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.56);

    &__price-info {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: $space * 2;

        .o-cart__sticky-checkout__price-text {
            padding-top: $space * 2;
            line-height: 26px;
        }
        .o-cart__sticky-checkout__price-value {
            line-height: $line-height--xlarge;
        }
    }

    &__button {
        width: 100%;
    }
}

;@import "sass-embedded-legacy-load-done:867";