.list {
    align-items: flex-end;
    display: flex;
    padding-bottom: $space * 2;
    padding-top: $space * 2;

    &__icon {
        color: $grey--dark;
        font-size: 24px;
        text-align: center;
        width: 45px;
    }

    &__title {
        flex: 1;
    }

    & + .list {
        border-top: solid 1px $grey--light;
    }

    &--wrap {
        flex-wrap: wrap;
    }

    &--wrap &__actions {
        width: 100%;
        margin-top: $space;
    }
}

@include media(md) {
    .list {
        &--wrap &__actions {
            width: auto;
            margin-top: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:882";