.o-blog-post-intro {
    text-align: center;
    margin-top: $space * 8;
    margin-bottom: $space * 14;

    &__pre-header {
        margin-bottom: $space;
    }

    &__headline {
        margin-bottom: $space * 4;
    }

    &__above-divider {
        margin-bottom: $space * 8;
    }
}

;@import "sass-embedded-legacy-load-done:1059";