//TODO: Remove when all usages are replaced
//Refactored version: styles/v2/components/_table.molecule.scss

.table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    border-radius: $border-radius;

    th,
    td { padding: $space ($space * 2) $space 0; }

    th {
        font-weight: $font-weight--bold;
        text-align: left;
        vertical-align: bottom;
    }

    td { vertical-align: middle; }

    &--bordered {
        th,
        td { padding: $space * 2; }

        th {
            background-color: $grey--light;
            @include border($where: top);
        }

        td { @include border($where: bottom); }

        tr:last-child > td { border: 0; }
    }

    &--striped,
    &--reversed-striped {
        th,
        td {
            padding: $space * 2;
        }

        td {
            @include border($where: 'right', $width: 2px, $color: #fff);

            &:last-child {
                border: 0;
            }
        }
    }

    &--striped {
        tbody > tr:nth-child(odd) {
            background-color: $grey--light;
        }
    }

    &--reversed-striped {
        th {
            @include border($where: 'right', $width: 2px, $color: #fff);
            background-color: $grey--light;

            &:last-child {
                border: 0;
            }
        }

        tbody > tr:nth-child(even) {
            background-color: $grey--light;
        }
    }
}

;@import "sass-embedded-legacy-load-done:765";