.bundled-products {
    padding-bottom: $space * 3;
    padding-top: $space * 3;

    &__item {
        margin-top: $space * 2;
        align-items: center;
        display: flex;

        img {
            margin-right: $space * 3;
            max-width: 75px;
            min-width: 75px;
        }

        p {
            margin-right: auto;
        }

        p a {
            color: $base-text-color;
        }
    }

    &__link {
        display: none;
        margin-left: $space * 3;
        white-space: nowrap;
    }

    .expandable.isExpanded .expandable__content {
        padding-bottom: $space * 2;
    }

    @include media(lg) {
        @include border(top);
        margin-top: $space * 6;

        &__item img {
            max-width: 50px;
            min-width: 50px;
        }
    }

    @include media(xl) {
        &__link {
            display: block;
        }
    }
}

;@import "sass-embedded-legacy-load-done:263";