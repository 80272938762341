@include media(lg) {
    .o-campaign-card {
        row-gap: 6 * $space;
        margin-bottom: 14 * $space;

        &__image {
            aspect-ratio: 2 / 1;
        }

        &__eye-catcher {
            top: 0;
            right: var(--padding);
        }

        &__cta {
            margin-top: 8 * $space;

            &-container {
                min-width: 33.33%;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1068";