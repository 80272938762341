.m-category-teaser {
    display: flex;
    flex-direction: column;
    padding: 4 * $space 2 * $space;
    gap: 4 * $space;
    background-color: $grey--light;
    border-radius: 2px;
    align-items: stretch;
    position: relative;
    width: 100%;
    height: 100%;

    &__category-title {
        display: -webkit-box;
        overflow: hidden;
        overflow-wrap: anywhere;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        min-height: 2 * $line-height--regular;
        padding: 0 2 * $space;
    }

    &__image {
        aspect-ratio: 1 / 1;
    }

    &__button {
        position: absolute;
        bottom: 2 * $space;
        right: 2 * $space;
    }
}

;@import "sass-embedded-legacy-load-done:984";