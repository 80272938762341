@include media(xxl) {
    .o-product-buy-box {

        &__sticky-abtest29887 {
            grid-template-columns: 70px 29.8fr 20.9fr 150px 328px;
            column-gap: 40px;
            padding-right: $space * 2;
        }

        &__name-abtest29887 {
            display: block;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1270";