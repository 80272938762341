@include media(md) {
    .hero-banner {
        &__full-width {
            .brandpage__banner {
                aspect-ratio: 2 / 1;
            }

            .brandpage__slim-banner {
                aspect-ratio: 4 / 1;
            }
        }

        &__eyecatcher-container {
            right: 70px;
            top: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1003";