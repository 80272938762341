.t-slider-v2 {
    position: relative;

    &__content {
        position: relative;
        display: flex;
        flex-wrap: nowrap;

        &--swipeable {
            overflow: hidden;
        }

        &--scrollable {
            overflow-x: scroll;
            margin-bottom: $space * 2;

            @include media(lg) {
                overflow: hidden;
                margin-bottom: 0;
            }
        }
    }

    &__button-left.button,
    &__button-right.button {
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 75px;
        height: 65px;
        opacity: 0.8;

        .fa {
            font-size: 20px;
        }

        @include media(md) {
            display: block;
        }
    }

    &__button-left.button:not(:disabled),
    &__button-right.button:not(:disabled) {
        border: none;
    }

    &__button-left.button {
        left: 0;
    }

    &__button-right.button {
        right: 0;
    }

    &--no-buttons {
        .t-slider-v2__button-left.button,
        .t-slider-v2__button-right.button {
            display: none;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1448";