//Product text styles
@include media(lg) {
    .a-txt {
        &__title-primary--responsive {
            font-family: $font-family--main;
            font-size: $font-size--large;
            font-weight: $font-weight--bold;
            line-height: $line-height--large;

            .fa {
                display: inline-block;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1163";