.ca-content--plus {
    &__faq {
        &--q {
            margin-bottom: $space;
        }

        &--a:not(:last-child) {
            margin-bottom: $space * 4;
        }
    }
}

;@import "sass-embedded-legacy-load-done:738";