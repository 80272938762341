//TODO: Deprecated - remove once all usages are gone
//Refactored version: v2/components/_rating-stars.molecule.scss

.product-rating {
    height: 15px;

    &--value {
        cursor: pointer;
        width: 55px;
        height: 12px;
        background: url(../../../images/rating/sprite-rating-stars@2x.png) no-repeat;
        background-size: 110px 22px;
    }

    $starWith: 11px;
    $numberOfStars: 5;
    @for $i from 0 through $numberOfStars {
        &--star-#{$i} {
            background-position: -($starWith * ($numberOfStars - $i)) 2px;
        }

        &--star-#{$i - 1}-and-half {
            background-position: -($starWith * ($numberOfStars - $i)) -10px;
        }
    }

    &:hover {
        cursor: pointer;
    }

    &--label {
        margin-left: 60px;
        color: $grey--dark;
        line-height: 1;
    }
}

;@import "sass-embedded-legacy-load-done:465";