//Refactors: styles/v1/components/_product-widget.scss

/* ------------------------------------ *\
    PRODUCT Image with Badges
\* ------------------------------------ */

.m-product-image {
    padding: 0;
    position: relative;

    &--full-width {
        width: 100%;
    }

    .lazy-load-container {
        display: block;
        min-height: 56px;
        overflow: hidden;
        position: relative;
    }

    &__image {
        display: block;
        left: 0;
        margin: 0 auto;
        top: 0;
        width: 100%;
    }

    .slider__content & {
        max-width: 100%;
        min-width: 100%;
    }

    .layout-list & {
        img {
            height: auto;
        }
    }

    &-badge-container {
        position: absolute;
        top: 0;
    }

    &__badge-container {
        display: flex;
        flex-direction: column;
    }

    &__badge-container--image {
        display: flex;
        flex-direction: row;
        padding: $space *2;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__badge--first {
        margin-bottom: $space;
    }

    &__image--medium {
        width: $image-size-m;
        min-height: $image-size-m;
        min-width: $image-size-m;
    }

    &__image--x-medium {
        max-height: $image-size-xm;
        max-width: $image-size-xm;
    }
}

;@import "sass-embedded-legacy-load-done:1018";