@include media(lg) {
    .o-newsletter-signup {
        // success component style
        &__success-container {
            padding: (19 * $space) (23 * $space) (21 * $space) (23 * $space);
        }

        &__success-check {
            margin-bottom: 8 * $space;
        }

        &__button {
            flex-basis: 0;
            flex-grow: 1;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1107";