@include media(lg) {
    .o-product-detail {
        display: flex;

        &__price {
            width: 50%;
        }

        &__image {
            margin-right: $space * 3;
        }

        &__title {
            margin-bottom: $space * 2;
            word-break: break-word;
        }

        &__sku {
            margin-bottom: $space * 2;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1377";