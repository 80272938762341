.o-password-sent {
    @include border(top);
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    padding-top: $space * 8;
    padding-bottom: $space * 14;

    &__image {
        margin-bottom: $space * 8;
    }

    &__subheadline {
        margin-bottom: $space * 4;
    }

    &__list {
        text-align: left;
    }

    &__caption {
        margin-bottom: $space * 8;
        max-width: 698px;
        margin-left: auto;
        margin-right: auto;
    }

    li {
        padding-left: 1.3em;
    }
}

;@import "sass-embedded-legacy-load-done:1249";