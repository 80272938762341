.address-management {
    input[type="radio"],
    input[type="checkbox"] {
        & + span {
            top: 0;
            transform: translateY(0%);
        }
    }
}

;@import "sass-embedded-legacy-load-done:234";