.o-cart__summary {
    &-price {
        margin-bottom: $space * 6;

        &__header {
            margin-bottom: $space * 8;
        }

        &__text {
            display: flex;
            justify-content: space-between;
            margin-bottom: $space;
        }

        &__text--column {
            margin: ($space * 3) 0;
            padding-bottom: ($space * 3);

            @include border($where: bottom, $color: $grey);
        }

        &__text--vertical {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: $space;
        }

        &__total {
            display: flex;
            justify-content: space-between;
            margin: ($space * 3) 0 ($space * 3);
            padding: ($space * 3) 0;
            flex-direction: column;

            @include border($where: top, $color: $grey);
            @include border($where: bottom, $color: $grey);
        }

        &__shipping {
            margin-bottom: $space * 2;
            padding-bottom: $space * 3;
            @include border($where: bottom, $color: $grey);
        }
    }

    &-offer-request {
        margin-top: $space * 4;
        text-align: right;

        &.button {
            font-size: $font-size--small;
        }
    }

    &-button {
        width: 100%;
        padding: 0 ($space * 4);
        margin: 0 0 ($space * 2) 0;
        display: flex;
    }

    &-buttons {
        margin-left: 0;
    }
}

.o-summary-prices-sticky {
    height: fit-content;
    position: sticky;
    top: 5%;
    width: 100%;
}

;@import "sass-embedded-legacy-load-done:831";