@include media(xl) {
    .o-category-tree {
        &__wrapper {
            margin-bottom: $space * 20;
        }

        &__header {
            margin-bottom: $space * 10;
        }

        &__block {
            padding-left: $space * 8;

            &:nth-child(1),
            &:nth-child(2) {
                margin-bottom: $space * 6;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1338";