@include media(md) {
    .o-cart-voucher-box {
        &__btn {
            flex-basis: 200px;
            margin-top: 0;
        }

        &__content {
            flex-wrap: nowrap;
        }

        &__input-wrapper {
            margin-right: $space * 4;
        }
    }
}

;@import "sass-embedded-legacy-load-done:841";