@include media(lg) {
    .o-column-block {
        width: 47%;

        &:not(:last-child) {
            margin-right: $space * 6;
        }
    }

    .o-column-block__container.-many {
        .o-column-block {
            width: 30%;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1097";