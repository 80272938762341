.nothing-found {
    &__container {
        margin-bottom: $space * 2;
        padding: ($space * 4) ($space * 4) 0 ($space * 4);

        @include media(lg) {
            margin-bottom: $space * 20;
        }

        a {
            font-weight: bold;
        }
    }

    &__title {
        margin-top: $space * 14;
    }

    &__list {
        list-style: none;
    }

    &__top-categories {
        margin: ($space * 12) auto ($space * 20);
        display: flex;
        flex-wrap: wrap;

        .category-block {
            padding-right: $space * 2;
            padding-bottom: $space * 2;

            &:last-child {
                margin-right: 0;
            }

            &--link {
                background-color: $grey--light;
                height: 100%;
                min-height: 290px;
                width: 100%;
                border-radius: 2px;
            }

            &--text {
                min-height: 32px;
                height: calc(100% - 297px);
                margin: ($space * 4) ($space * 4) 0;
                font-size: $font-size--medium;
            }

            &--button {
                float: right;
                margin: 0 ($space * 2) ($space * 2);
            }

            &--image {
                min-width: 187px;
                height: 187px;
                margin: ($space * 4) ($space * 2);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }
    }

    &__other-categories {
        margin-bottom: $space * 26;

        .category-link {
            font-size: $font-size--medium;
            margin: 0 ($space * 2) ($space * 4) ($space * 2);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: block;
        }
    }

    &__brand {
        &--container {
            margin-top: $space * 6;
            margin-bottom: $space * 8;

            @media (max-width: 479px) {
                padding-right: $space * 2;
            }
        }

        &--logos {
            margin-top: $space * 14;
            margin-bottom: $space * 14;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-height: 550px;
            justify-content: center;

            @include media(md) {
                max-height: 440px;
            }

            @include media(lg) {
                margin-bottom: $space * 14;
            }
        }

        &--logo {
            height: 100px;
            width: 160px;
            text-align: center;
            margin: $space 3px;

            @include media(md) {
                width: 170px;
            }

            @include media(xl) {
                width: 176px;
            }

            @include media(xxl) {
                margin: $space 2px;
            }
        }

        &--btn {
            width: 90%;

            @include media(lg) {
                width: 50%;
            }
            @include media(xxl) {
                width: 25%;
            }
        }
    }

    &__product-history {
        width: inherit;
    }
}


;@import "sass-embedded-legacy-load-done:790";