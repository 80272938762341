@include media(xl) {
    .o-column-block {
        &:not(:last-child) {
            margin-right: $space * 8;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1099";