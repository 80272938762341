//elements
@import "v1/elements/annotation";
//@import "v1/elements/badges"; //in the critical path
@import "v1/elements/blockquote";
@import "v1/elements/buttons";
@import "v1/elements/shadows";
@import "v1/elements/tag";

//components
@import "v1/components/address-management";
@import "v1/components/billpay";
@import "v1/components/brands";
@import "v1/components/bundles-products";
@import "v1/components/collapsibles";
//@import "v1/components/cookie-hint"; //in the critical path
@import "v1/components/footer-seo";
@import "v1/components/form-steps";
@import "v1/components/headline";
@import "v1/components/hint";
@import "v1/components/info";
@import "v1/components/input-group";
//@import "v1/components/loadable"; //in the critical path
@import "v1/components/marquee";
@import "v1/components/menu";
@import "v1/components/notification";
@import "v1/components/order-cancellation";
@import "v1/components/page-size-selector";
@import "v1/components/password-help";
@import "v1/components/paypal-express-checkout-button.scss";
@import "v1/components/amazon-pay-checkout-buttton.scss";
@import "v1/components/press";
@import "v1/components/product";
@import "v1/components/product-buy-followup";
@import "v1/components/product-documents";
@import "v1/components/product-filters";
@import "v1/components/product-hightlights";
@import "v1/components/product-history";
@import "v1/components/product-list";
@import "v1/components/product-rating";
@import "v1/components/product-relation-highlight";
@import "v1/components/product-relations";
@import "v1/components/product-relations-search";
@import "v1/components/product-specs";
@import "v1/components/product-configurator";
@import "v1/components/product-widget";
@import "v1/components/product-widget-slider";
@import "v1/components/products-with-attributes";
@import "v1/components/progress";
@import "v1/components/recommendations";
@import "v1/components/shopping-list-widget";
@import "v1/components/testimonials";

//layout
@import "v1/layout/cart";
@import "v1/layout/checkout";
@import "v1/layout/customer-account";
@import "v1/layout/footer";
@import "v1/layout/jobs";
//@import "v1/layout/main"; //in the critical path
@import "v1/layout/navigation";
//@import "v1/layout/navigation-mobile"; //in the contorion-v2-mobile
@import "v1/layout/pdp";
//@import "v1/layout/pdp-mobile"; //in the contorion-v2-mobile
@import "v1/layout/productline";
@import "v1/layout/teams";

/* Restored styles from the critical path */
.main-content,
.navigation,
.header-login-form,
.menu-header-dropdown,
footer.footer {
    opacity: 1;
    animation: none;
}

;@import "sass-embedded-legacy-load-done:188";