@include media(xl) {
    .o-cart__sticky-checkout {
        &__price-info {
            flex: 0 0 66.67%;
        }

        &__buttons {
            flex: 0 0 33.33%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:871";