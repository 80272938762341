@include media(lg) {
    .t-product-highlight {
        padding-bottom: ($space * 20);

        &__main-content {
            margin: 0 0 ($space * 6) 0;
        }

        &__sub-content {
            margin-right: 0;
            margin-left: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1345";