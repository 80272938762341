//Refactors: static/assets/Yves/styles/v1/components/_product-history.scss

.o-product-history {
    &__container {
        padding: ($space * 4) 0;
    }

    &__erase-link {
        padding-bottom: $space * 4;
        margin-left: $space * 2;
    }

    &__item {
        padding: $space* 2;
    }

    .t-slider__content {
        margin: 0 ($space * 2);
        display: flex;
    }

    @include media(md) {
        &__erase-link {
            padding-bottom: $space * 4;
            margin-left: 0;
        }

        .t-slider__content {
            margin: 0 ($space * 8);
        }
    }
}

.last-viewed-list {
    margin-bottom: $space * 14;
}

;@import "sass-embedded-legacy-load-done:1291";