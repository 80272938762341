@include media(md) {
    .o-product-list {
        &__content {
            &.-is-empty {
                margin: 6 * $space 28 * $space 8 * $space;
            }
        }
        &__action-button {
            display: none;
        }
        &__action-link {
            display: block;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1214";