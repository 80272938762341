//Refactors: styles/v1/components/_recommendations.scss

/* ------------------------------------ *\
    RECOMMENDATIONS
\* ------------------------------------ */

.o-product-recommendations-v2 {
    &__placeholder {
        height: 390px;
        &:empty {
            align-items: center;
            display: flex;
            justify-content: center;
            &::after {
                animation: spin 0.5s infinite steps(9);
                color: $blue;
                content: $fa-var-cog;
                font-family: "FontAwesome";
                font-size: $font-size--large;
            }
        }
    }

    &.collapsed {
        display: none;

        @include media(lg) {
            display: flex;
        }
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    0% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}


;@import "sass-embedded-legacy-load-done:1253";