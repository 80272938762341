@include media(lg) {
    .o-campaign-slider--container {
        padding-left: 0;
        padding-right: 0;
    }

    .o-campaign-slider-content {
        width: calc((100% - 40px) / 2);
        margin-right: $space * 8;

        &__sticky-eyecatcher {
            top: 0;
            right: $space * 6;
        }

        &__brand-logo-wrapper {
            padding: ($space * 2) ($space * 4);
        }
    }
}

;@import "sass-embedded-legacy-load-done:1141";