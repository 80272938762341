.m-payment-method-stripe-eps {
    width: 100%;
    &__select {
        width: 100%;
        height: 40px;
        border-radius: $border-radius;
        appearance: none;
        line-height: 1em;
        cursor: pointer;
        background-size: 15px;
        font-size: 11px;
        @include border;


        &:focus {
            outline: none;
            background-color: $grey--light;
            border-color: $theme-color;
        }
    }

    &__error {
        overflow-wrap: break-word;
    }
}

;@import "sass-embedded-legacy-load-done:1413";