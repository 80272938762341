/* ------------------------------------ *\
    BREADCRUMBS
\* ------------------------------------ */

@include media(lg) {
    .breadcrumbs {
        display: block;
        overflow: unset;

        &__item {
            display: inline-flex;
            line-height: $base-line-height;
        }
    }
}

;@import "sass-embedded-legacy-load-done:942";