@include media(md) {
    .o-campaign-slider--container {
        padding-left: 20px;
        padding-right: 20px;
    }
    .o-campaign-slider-content {
        width: calc((100% - 20px) / 2);
        margin-right: $space * 4;
        &:last-child {
            margin-right: 0;
        }

        &__sticky-tl,
        &__sticky-tr {
            top: $space * 6;
        }

        &__sticky-bl,
        &__sticky-br {
            bottom: $space * 6;
        }

        &__sticky-eyecatcher {
            top: $space * 6;
        }

        &__brand-logo-wrapper {
            padding: $space * 2;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1137";