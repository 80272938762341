.header-login-form {
    background-color: #fff;
    border-radius: $border-radius;
    color: $base-text-color;
    display: none;
    left: 50%;
    max-width: 230px;
    min-width: 230px;
    padding: ($space * 4) ($space * 4);
    position: absolute;
    top: calc(100% + 15px);
    transform: translateX(-50%);
    box-shadow: $shadow3;
    font-weight: $font-weight--base;

    .hover &,
    &.active {
        display: block;
    }

    h3 {
        margin: 0;
        line-height: 1em;
        text-align: left;
        position: relative;

        &,
        > span {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        span {
            line-height: 1em;
            color: $green;
            font-size: $font-size--small;
        }

        .fa {
            color: $green;
            font-size: $font-size--regular;
            margin-right: 3px;
        }
    }

    &::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid #fff;
        position: absolute;
        bottom: calc(100% - 5px);
        left: 50%;
        transform: translateX(-50%);
    }

    &::after {
        background: transparent;
        content: "";
        height: 23px;
        left: 0;
        width: 100%;
        position: absolute;
        top: -23px;
    }

    form {
        margin: ($space * 4) 0  ($space * 2);
    }

    .form__field {
        margin: 10px 0;
    }

    a {
        display: block;
        font-weight: $font-weight--base;
        text-align: left;
    }

    button {
        width: 100%;
        margin: ($space * 2) 0;

        .button__loading-indicator {
            color: #fff;
        }
    }

    .invalid-user-information {
        background-color: $red;
        color: #fff;
        width: calc(100% + 40px);
        margin: ($space * 2) (-$space * 4);
        padding: 10px;
    }

    &__footer {
        background: $grey--navigation;
        margin: 0 (-$space * 4) (-$space * 4) (-$space * 4);
        padding: $space * 4;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;

        h3 {
            color: #fff;
            margin: 0 0 $space 0;
        }

        a {
            color: $theme-color;
            font-weight: $font-weight--bold;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .tooltip {
        min-width: 190px;
        max-width: 190px;
    }

    @include media(lg) {
        input {
            height: 40px;
        }

        input.touched.invalid {
            background-position: calc(100% - 10px) -93px;
        }

        input.touched.valid {
            background-position: calc(100% - 10px) -64px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:883";