.m-badge {
    border: solid 1px;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    width: fit-content;

    &.-small {
        padding: 3px 6px;
        gap: 3px;
    }

    &.-large {
        padding: $space 2 * $space;
        gap: 4px;
    }

    &.-yellow-outlined {
        border-color: $yellow;
        background-color: white;
    }

    &.-green-outlined {
        border-color: $green;
        background-color: white;
    }

    &.-red-outlined {
        border-color: $red;
        background-color: white;
    }

    &.-black-outlined {
        border-color: $grey--darker;
        background-color: white;
    }

    &.-yellow-filled {
        background-color: $yellow;
        border-color: $yellow;
    }

    &.-green-filled {
        background-color: $green;
        border-color: $green;
    }

    &.-red-filled {
        background-color: $red;
        border-color: $red;
    }

    &.-black-filled {
        background-color: $grey--darker;
        border-color: $grey--darker;
    }

    &__icon-container {
        text-align: center;

        &.-small {
            width: 10px;
            height: 10px;
        }

        &.-large {
            width: 18px;
            height: 18px;
        }

        &.-fa-small {
            font-size: 10px;
            line-height: 1;
        }

        &.-fa-large {
            font-size: $font-size--medium-plus;
        }

        &.-contorion-small {
            font-size: 8px;
            line-height: 10px;
        }

        &.-contorion-large {
            font-size: $font-size--regular;
            line-height: $line-height--regular-plus;
        }
    }

    &__icon-left {
        &.-yellow {
            color: $yellow;
        }

        &.-green {
            color: $green;
        }

        &.-red {
            color: $red;
        }

        &.-white {
            color: white;
        }
    }
}

;@import "sass-embedded-legacy-load-done:961";