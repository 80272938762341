.o-burger-menu {
    &__container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    &__icon {
        color: $blue;
        font-size: $font-size--large;
    }

    &__header {
        background: #fff;
        display: flex;
        justify-content: space-between;
        width: 80%;
        padding: $space * 3;
        border-bottom: 1px solod $grey--light;
        @include border(bottom, $color: $grey--light);
    }

    &__content {
        background: #fff;
        width: 80%;
        overflow-y: scroll;
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
    }

    &__block {
        display: none;
        background: #fff;
        flex-direction: column;
        min-width: 100%;
        z-index: $z4;
        height: max-content;
        padding-bottom: ($space * 25);

        &.-active {
            display: flex;
            z-index: $z5;
        }
    }

    &__item {
        position: relative;
        padding: $space * 3;
        display: flex !important;
        align-items: center;
        @include border(bottom, $color: $grey--light);

        &::before {
            font-size: $font-size--medium-plus;
            color: $grey;
            margin-right: $space * 2;
        }

        &.-forward::after {
            @include icon($fa-var-chevron-right);
            position: absolute;
            right: 12px;
            font-size: $font-size--medium;
            color: $grey;
        }

        &.-back {
            padding-left: $space * 8;
        }

        &.-back::before {
            @include icon($fa-var-chevron-left);
            position: absolute;
            left: 12px;
            font-size: $font-size--medium;
            color: $blue;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1460";