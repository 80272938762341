@include media(lg) {
    .o-product-buy-box-v2 {
        padding-left: $space * 2;
        padding-right: $space * 2;

        &__product-name {
            margin-top: $space * 2;
        }

        &__bundle-label {
            margin-top: $space * 6;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1306";