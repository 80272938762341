.o-product-widget-small {
    display: grid;
    grid-row-gap: 3 * $space;
    grid-column-gap: 2 * $space;
    width: 100%;
    height: fit-content;
    min-width: 200px;

    &--tile-desktop-view {
        padding: 2 * $space;
    }

    &--list-view {
        padding: 2 * $space 3 * $space;
        grid-template-columns: 80px 1fr 160px 60px;
    }

    &--tile-view {
        grid-template-columns: 125px 1fr;
        padding: 2 * $space;
    }

    &__image-container {
        position: relative;
        display: flex;
        justify-content: center;

        &-bundle {
            height: 180px;
        }
    }

    &__image-container--list-view {
        grid-column: 1;
        grid-row: calc(1 / 4);
    }

    &__image-container--tile-view {
        grid-row: calc(1 / 4);
        grid-column: 1;
        flex-direction: column-reverse;
        justify-content: flex-end;
    }

    &__image {
        display: block;
        top: 0;
        left: 0;
    }

    &__image--tile-desktop-view {
        width: $image-size-xm;
        height: $image-size-xm;
        margin: 0 2 * $space;
    }

    &__image--list-view {
        width: $image-size-m;
        height: $image-size-m;
    }

    &__image--tile-view {
        width: 125px;
        height: 125px;
    }

    &__image--faded {
        opacity: 0.4;
    }

    &__badges {
        display: flex;
        width: 100%;
    }

    &__badges--list-view {
        display: none;
    }

    &__badges--tile-desktop-view {
        justify-content: space-between;
        flex-direction: row;
        position: absolute;
        top: 0;
    }

    &__badges--tile-view {
        flex-direction: column-reverse;
        justify-content: flex-start;
        gap: $space;
    }

    &__product-details--list-view {
        grid-row: 1;
        grid-column: 2;
    }

    &__product-details--tile-view {
        grid-row: 1;
        grid-column: 2;
    }

    &__product-name--tile-desktop-view {
        display: -webkit-box;
        overflow: hidden;
        overflow-wrap: anywhere;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 2 * $line-height--regular;
    }

    &__rating {
        margin-top: $space;
        height: 12px;
    }

    &__rating--hidden {
        display: none;
    }

    &__delivery--list-view,
    &__delivery--tile-view {
        grid-row: 2;
        grid-column: 2;
    }

    &__new-delivery-date,
    &__old-delivery-date {
        &--hidden {
            display: none;
        }
    }

    &__delivery--list-view {
        >.o-product-widget-small__new-delivery-date {
            display: none;
        }
        >.o-product-widget-small__old-delivery-date--hidden {
            display: block;
        }
    }

    &__price-tag--list-view {
        grid-row: calc(1 / 4);
        grid-column: 3;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &__price-tag--tile-view {
        grid-row: 3;
        grid-column: 2;
    }

    &__retail-price--tile-desktop-view {
        height: 18px;
    }

    &__current-price {
        margin-bottom: $space;
    }

    &__vat_price_per_piece--list-view {
        display: contents;
    }

    &__variants {
        background-color: $grey--light;
        display: flex;
        justify-content: center;
    }

    &__variants {
        padding: $space 0;
    }

    &__variants--list-view {
        grid-row: 3;
        grid-column: 2;
        padding: 3px $space;
        width: fit-content;
    }

    &__variants--tile-view {
        grid-row: 4;
        grid-column: calc(1 / 3);
    }

    &__units-per-package-placehoder {
        margin-bottom: $line-height--large;
    }

    &__sponsored {
        height: 15px;
        padding-left: $space * 2;
    }
}

;@import "sass-embedded-legacy-load-done:1237";