//TODO: Deprecated - Remove once all usages are gone
//Refactored Version: /v2/components/_product-widget.organism.scss

/* ------------------------------------ *\
    PRODUCT WIDGET
\* ------------------------------------ */

.product-widgets {
    position: relative;

    .product-widgets__placeholder {
        align-items: center;
        display: none;
        min-height: 50vh;
        justify-content: center;
        width: 100%;
    }

    &.loading {
        .product-widgets__placeholder {
            display: flex;
        }

        .product-widgets__item {
            display: none;
        }
    }

    //TODO: BOSS-21422 remove, added for AB test
    &__item--extended {
        .product-widget__image {
            width: percentage(calc(1 / 3));

            @include media(lg) {
                width: 100%;
            }
        }

        .product-widget__data {
            width: percentage(calc(2 / 3));
            padding-left: $space * 4;

            @include media(lg) {
                width: 100%;
                padding-left: 0;
            }
        }

        .m-sponsored-label {
            width: 100%;
            margin-bottom: $space * 4;
        }
    }
}

.product-widget {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: $space * 2;
    padding-bottom: $space * 2;
    padding-left: $space * 2;
    padding-right: $space * 2;

    .shopping-list-widget [class^="icon--"] {
        font-size: 20px;
        left: 50% !important;
        position: absolute;
        top: 50% !important;
        transform: translate3d(-50%, -50%, 0) !important;
    }

    .shopping-list-widget .tooltip {
        left: auto;
        right: 0;
        top: 43px;
    }

    .shopping-list-widget .tooltip::before {
        left: auto;
        right: 12px;
    }

    .shopping-list-widget .tooltip::after {
        left: auto;
        right: 11px;
    }

    &__badge-container {
        position: absolute;
        display: flex;
        flex-direction: column-reverse;
        gap: $space;
        z-index: $z1;
        top: 0;

        @include media(lg) {
            flex-direction: row;
            width: 100%;
        }
    }

    &__badge-container--image {
        display: flex;
        flex-direction: row;
        padding: $space *2;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__badge--first {
        margin-bottom: $space;

        @include media(lg) {
            margin-bottom: 0;
        }
    }

    &__data {
        width: 100%;

        .layout-list & {
            @include media(lg) {
                width: percentage(calc(10 / 12));
            }
            @include media(xl) {
                width: percentage(calc(22 / 24));
            }
        }
    }

    //TODO: BOSS-21237 remove, added for AB test
    @include media(lg) {
        .layout-icon &__data-extended {
            background: #fff;
            position: absolute;
            bottom: 0;
            padding: 0 ($space * 2);
        }

        .layout-icon &__data-mobile {
            margin-bottom: $space * 5;
        }
    }

    &__info {
        width: 100%;

        .layout-list & {
            @include media(lg) {
                padding: 0 ($space * 2);
                width: percentage(0.65);
            }

            @include media(xl) {
                padding: 0 ($space * 4);
                width: percentage(0.7);
            }

            @include media(xxl) {
                padding: 0 ($space * 4);
                width: percentage(0.75);
            }
        }

        &--name {
            overflow: hidden;
            text-overflow: ellipsis;

            @include media(lg) {
                height: 36px;
            }

            .layout-list & {
                height: auto;
                overflow: auto;
            }
        }
    }

    &__buy-block {
        width: 100%;

        .layout-list & {
            @include media(lg) {
                width: percentage(0.35);
            }

            @include media(xl) {
                text-align: center;
                width: percentage(0.3);
            }

            @include media(xxl) {
                width: percentage(0.25);
            }
        }
    }

    &__price-per-unit {
        @include media(xl) {
            max-width: 65px;
        }
    }
}

.product-widgets__item {
    width: 100%;
    @include media(lg) {
        width: percentage(calc(1 / 2));

        .layout-icon & {
            flex-direction: column;
            justify-content: space-between;
        }
    }
    @include media(xl) {
        width: percentage(calc(1 / 3));
    }
    @include media(xxl) {
        width: percentage(calc(1 / 4));
    }

    .layout-list & {
        width: 100%;
    }
}

.product-widget__image {
    padding: 0;
    position: relative;
    width: 100%;
    min-height: 180px;

    .lazy-load-container {
        display: block;
        min-height: 56px;
        overflow: hidden;
        position: relative;
    }

    &__disabled {
        opacity: 0.4;
    }

    img {
        display: block;
        left: 0;
        margin: 0 auto;
        max-height: 180px;
        max-width: 180px;
        top: 0;
        width: 100%;
        padding-top: 20px;

        @include media(lg) {
            padding-top: 0;
        }
    }

    .slider__content & {
        max-width: 100%;
        min-width: 100%;
    }

    .layout-list & {
        img {
            height: auto;
        }

        @include media(lg) {
            width: percentage(calc(2 / 12));
            .badge {
                display: none;
            }
        }

        @include media(xl) {
            width: percentage(calc(2 / 24));
        }
    }

    &-badge-container {
        position: absolute;
        top: 0;
    }
}

.product-widget__prices,
.product-widget__footer {
    width: 100%;
}

.product-widget__prices {
    .badge {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
    }

    .noprice::before {
        content: "\00a0";
        .layout-list & {
            content: "";
        }
    }

    .layout-list & .badge {
        @include media(lg) {
            display: block;
        }
    }

    &-suggestions {
        height: 18px;
    }
}

.product-widget__footer {
    .product-widget-slider & {
        margin-bottom: 0;

        @include media(lg) {
            margin-bottom: 48px;
        }
    }

    & > .button {
        height: $space * 8;
        padding: 9px 8px 8px;

        @include media(lg) {
            padding-top: 7px;
        }
    }
}


;@import "sass-embedded-legacy-load-done:514";