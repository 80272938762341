.m-quantity-field {
    width: 100%;

    &__button {
        min-width: 50px;
        height: 50px;
    }
    &__input {
        flex-grow: 1;
        height: 50px;
    }
    &__tooltip {
        text-align: center;
        display: none;
    }
}

;@import "sass-embedded-legacy-load-done:827";