@include media(lg) {
    .o-cart__sticky-checkout {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        padding: ($space * 2) ($space * 8);

        &__price-info {
            width: 100%;
            flex: 0 0 50%;
            margin-bottom: 0;
        }

        &__buttons {
            width: 100%;
            flex: 0 0 50%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:874";