.o-column-block {
    min-width: 210px;
    width: 100%;

    &.-clickable {
        cursor: pointer;
    }

    &:not(:last-child) {
        margin-bottom: $space * 8;
    }

    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: $space * 8 0;
    }

    &__image {
        aspect-ratio: 3 / 2;
    }

    &__text {
        margin-top: $space * 4;
    }

    &__headline-text {
        margin-bottom: $space * 2;
    }

    &__button {
        margin-top: $space * 4;
        width: 160px;
        border-color: $grey--darker;
    }
}

;@import "sass-embedded-legacy-load-done:1086";