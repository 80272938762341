//Refactors: styles/v1/layout/_pdp.scss

@include media(lg) {
    .o-product-info {
        margin-top: 0;
        width: 62%;
    }
}


;@import "sass-embedded-legacy-load-done:1278";