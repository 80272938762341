@include media(xl) {
    .cart-v2 .t-cart {
        &__right-container {
            padding: ($space * 14) ($space * 6) ($space * 20);
        }
    }

    .cart-v2__flash-bag {
        .alert {
            margin-top: $space * 10;
        }
    }
}

;@import "sass-embedded-legacy-load-done:804";