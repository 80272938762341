.m-menu-item {
    display: flex;
    align-items: center;
    height: 50px;
    min-width: 280px;
    width: calc(100vw - 64px);
    padding: 0 0 0 ($space * 3);
    font-size: $font-size--medium;
    line-height: $line-height--large;
    background-color: white;

    &[class*=fa-] {
        font-size: $font-size--medium;
        line-height: 50px;

        a {
            margin-left: 47px;
        }
    }

    &[class*=fa-]::before,
    &[class*=icon--]::before {
        margin-left: 21px;
        margin-right: 6px;
        justify-content: center;
        color: $grey--dark;
        text-align: center;
        width: 20px;
    }

    &[class*=fa-]::before {
        font-size: $font-size--icon;
        position: absolute;
        line-height: 50px;
    }

    &[class*=icon--]::before {
        font-size: $font-size--icon;
        line-height: $line-height--icon;
    }

    &__left-image {
        height: 32px;
        width: 32px;
        margin-right: $space;
        vertical-align: middle;
    }

    &__right-icon {
        font-size: $font-size--regular;
        margin-left: auto;
        color: $grey--dark;
    }

    &__text {
        font-family: $font-family--regular;
        display: flex;
        align-items: center;
        width: 100%;
        padding-left: $space * 3;

        span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: calc(100vw - 150px);
            padding-right: $space;
        }
    }

    &__headline#{&} {
        min-height: 58px;
        height: 100%;
        padding-bottom: $space * 2;
        width: 100%;

        .m-menu-item__right-icon {
            font-size: $font-size--medium;
            padding-right: $space * 7;
        }

        .m-menu-item__text {
            font-size: $font-size--xlarge;
            line-height: $line-height--xlarge;
            font-family: $font-family--main;
        }

        a {
            width: 100%;
        }
    }

    &__bordered {
        height: 61px;

        p {
            margin-left: $space * 2;
            padding: ($space * 4) 0;
            @include border(bottom, $color: $grey--medium);
        }

        &[class*=fa-],
        &[class*=fa-]::before {
            line-height: 60px;
        }

        &[class*=fa-] p {
            line-height: $line-height--large;
        }
    }

    a {
        width: calc(100% - 40px);
        text-decoration: none;
        color: $grey--darker;
    }

    &.without-left-icon a {
        width: 100%;
    }

    a:hover,
    a:hover &__right-icon {
        text-decoration: none;
        color: $blue;

        .navigation-v2 & {
            color: $grey--darker;
        }
    }
}

.black--icon {
    &[class*=fa-]::before,
    &[class*=icon--]::before {
        color: $grey--darker;
    }
}

;@import "sass-embedded-legacy-load-done:1007";