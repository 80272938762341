//TODO: Deprecated remove when all usages are gone
//Refactored Version: static/assets/Yves/styles/v2/templates/_slider.template.scss
.slider {
    position: relative;

    &__content {
        position: relative;
        display: flex;
        flex-wrap: nowrap;

        &--swipeable {
            overflow: hidden;
        }

        &--scrollable {
            overflow-x: scroll;
            margin-bottom: $space * 2;

            @include media(lg) {
                overflow: hidden;
                margin-bottom: 0;
            }
        }
    }

    &__button-left.button,
    &__button-right.button {
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include media(md) {
            display: block;
        }
    }

    &__button-left.button {
        left: 0;
    }

    &__button-right.button {
        right: 0;
    }

    &--no-buttons {
        .slider__button-left.button,
        .slider__button-right.button {
            display: none;
        }
    }
}

;@import "sass-embedded-legacy-load-done:913";