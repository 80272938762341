@include media(xxl) {
    .o-category-tree {
        &__block,
        &__block:nth-child(1),
        &__block:nth-child(2) {
            margin-bottom: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1340";