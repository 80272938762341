@include media(md) {
    .ca-company-size-nudge-modal {
        width: 420px;
        &__form,
        &__success {
            padding: 0 $space*8 $space*8;
        }

        &__success-subtext {
            padding-bottom: $space*6;
        }
    }
}

;@import "sass-embedded-legacy-load-done:743";