.o-bundle-products-modal {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 0 $space * 3 $space * 3;
        width: 100%;
        max-height: unset;
        margin: 0 $space * 3;
        border-radius: 2px;
        height: 95%;

        &--products {
            overflow-y: auto;
        }
    }

    &__title {
        border-top: none;
        padding: $space * 2 0 0;

        button {
            font-size: 24px;
        }
    }

    &__header {
        padding-bottom: $space;
    }

    &__product {
        padding-bottom: $space * 3;
    }

    &__overlay {
        cursor: unset !important;
    }

    &__close {
        width: 40px;
        height: 40px;
    }
}

;@import "sass-embedded-legacy-load-done:1379";