.product-buy-followup {
    background-color: #fff;
    font-size: $font-size--small;
    line-height: 1em;
    margin: ($space * 3) (-$space * 3) (-$space * 3) (-$space * 3);
    padding: ($space * 2) ($space * 3);

    &__product {
        align-items: flex-start;
        display: flex;
        margin-top: $space * 2;

        img {
            margin-right: $space * 2;
            max-width: 45px;
        }

        p {
            line-height: 1em;
        }
    }

    &__price {
        color: $theme-color;
        font-size: $font-size--regular;
        line-height: $line-height--regular;
    }

    a {
        font-weight: $font-weight--bold;
        margin-left: auto;
        margin-top: $space;
    }

    @include media(xxl) {
        &__product {
            img {
                max-width: 70px;
            }
        }

        a {
            margin-top: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:418";