.bg--transparent { background-color: transparent !important; }
.bg--white { background-color: #fff !important; }
.bg--theme { background-color: $theme-color !important; }
.bg--grey { background-color: $grey !important; }
.bg--grey--light { background-color: $grey--light !important; }
.bg--grey--lighter { background-color: $grey--lighter !important; }
.bg--grey--darker { background-color: $grey--darker !important; }

.bg--success { background-color: $green !important; }

.bg--red,
.bg--error { background-color: $red !important; }

;@import "sass-embedded-legacy-load-done:143";