//Refactors: styles/v1/components/_product-widget.scss

/* ------------------------------------ *\
    PRODUCT WIDGET - XXL
\* ------------------------------------ */
@include media(xxl) {
    .o-product-widget {
        &__info--list-view {
            padding: 0 ($space * 4);
        }

        &__buy-block--list-view {
            text-align: center;
        }

        &__prices--list-view {
            align-items: flex-start;
            text-align: initial;
            padding: 0 $space * 2;
        }
    }
}
;@import "sass-embedded-legacy-load-done:1244";