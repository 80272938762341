.m-brushed-text {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    &__line {
        width: fit-content;
        height: auto;
        background-image: url('/images/svg/brushed-text-background.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: $space 4 * $space;
        filter: drop-shadow(0 1px 3px rgba(#000, 0.12));

        // refers to first line: last-of-type because of reversed order (required for z-index)
        &:not(:last-of-type) {
            margin-top: -$space;
        }
    }

    .-stier {
        background-image: url('/images/svg/brushed-text-stier-background.svg');
    }

    &__stier-logo {
        width: 100px;
    }
}

;@import "sass-embedded-legacy-load-done:972";