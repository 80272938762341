@include media(xl) {
    .o-product-buy-box-v2 {
        margin-bottom: $space * 14;

        &__product-name {
            margin-bottom: $space * 4;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1299";