@include media(md) {
    .t-cart__main-container {
        .t-cart-empty {
            padding: $space * 8 0;

            &__wrapper {
                padding: $space * 8;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:845";