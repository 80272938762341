.t-cart-v2-item {
    box-shadow: $shadow6;
    margin-bottom: $space * 4;
    background-color: white;
    padding: $space * 4;

    &:last-child {
        margin-bottom: 0;
    }

    &__main {
        padding-bottom: $space * 4;
        display: flex;
        justify-content: space-between;
        @include border($where: bottom, $color: $grey--medium);
    }

    &__image {
        min-width: 80px;
        width: 80px;
        height: 80px;

        .cart__img {
            width: 100%;
        }
    }

    &__info {
        flex-grow: 1;
        padding-right: $space * 4;
    }

    &__quantity-price {
        display: flex;
        flex-direction: column;
        min-width: 150px;
        width: auto;
        margin-top: $space * 4;
    }

    &__info-and-price {
        display: block;
        padding-left: $space * 4;
    }

    &__bottom-links {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
}

;@import "sass-embedded-legacy-load-done:855";