@include media(md) {
    .o-product-highlight {
        &__image-container {
            display: flex;
            justify-content: center;
            position: relative;
            max-width: 700px;
            width: 100%;
        }

        &__brushed-text {
            position: absolute;
            top: 0;
            left: 0;
            padding-left: 4 * $space;
        }

        &__image--extra-top-spacing {
            padding-top: 9 * $space;
        }

        &__cta-button {
            max-width: 160px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1112";