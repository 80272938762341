.m-product-price {
    position: relative;

    &__price {
        margin-right: $space;
        padding-bottom: $space;
    }

    &__price-per-unit {
        white-space: normal;
    }

    .badge {
        display: none;
        position: absolute;
        right: $space * 2;
        top: 0;
    }

    .noprice::before {
        content: "\00a0";
        .layout-list & {
            content: "";
        }
    }

    .layout-list & .badge {
        @include media(lg) {
            display: block;
        }
    }

    &-suggestions {
        height: 18px;
    }

    &__price--hint {
        height: 24px;
        font-size: $font-size--small;
        line-height: $line-height--small;
    }
}

;@import "sass-embedded-legacy-load-done:1177";