.page-cart-index {
    .main-wrapper {
        background-color: $grey--light;
    }
}

.m-cart__alert {
    margin-bottom: $space;
}

.cart-v2 .t-cart {
    &__loading-state {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        right: 0;
        background: rgba(204, 204, 204, 0.47);
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;

        & .icon::after {
            content: " ";
            display: block;
            width: 65px;
            height: 65px;
            border-radius: 50%;
            border: 7px solid $blue;
            border-color: $blue transparent $blue transparent;
            animation: icon-animation 1.2s linear infinite;
        }
        @keyframes icon-animation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    &__top-container {
        margin-top: $space * 6;
    }

    &__main-container {
        display: block;
    }

    &__bottom-container {
        width: 100%;
        background-color: #fff;
    }

    &__sticky-checkout {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: $z5;
    }

    &__items {
        margin-bottom: $space * 4;
    }

    &__left-container {
        width: 100%;
        padding: ($space * 6) 0;
    }

    &__items-header {
        margin-bottom: $space * 2;
    }

    &__items-quantity {
        margin-bottom: $space * 4;
    }
    &__right-container {
        width: 100%;
        padding: ($space * 8) ($space * 4) ($space * 14);
    }
    &__last-viewed {
        &--container {
            position: initial;
        }
    }
}

.cart-v2__flash-bag {
    .alert {
        padding-top: $space * 4;
        padding-bottom: $space * 4;
        margin-bottom: 0;
        margin-top: $space * 6;
    }
}

;@import "sass-embedded-legacy-load-done:804";