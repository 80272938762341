@include media(md) {
    .o-product-buy-box {
        &__sticky-abtest29887 {
            grid-template-columns: 70px 44.4fr 100px;
            column-gap: 30px;
        }

        &__quantity-field-abtest29887,
        &__name-abtest29887 {
            display: none;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1264";