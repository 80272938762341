.m-energy-label {
    &__modal {
        display: none;

        &-wrapper {
            width: auto;
        }

        &-content {
            text-align: center;
            padding: 0 ($space * 13) $space ($space * 13);
        }
    }

    &__image {
        max-width: 200px;
    }
}

;@import "sass-embedded-legacy-load-done:1216";