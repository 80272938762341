.o-cart-items {
    &__action-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    &__btn-text {
        font-size: $font-size--regular-plus;
        line-height: $line-height--regular;
    }

    &__btn {
        background: transparent;
        & [class^=icon--] {
            font-size: $font-size--regular-plus;
        }
    }
}

;@import "sass-embedded-legacy-load-done:863";