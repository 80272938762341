//Refactors: styles/v1/components/_product-specs.scss

@include media(lg) {
    .o-product-specs {
        padding: 0 $space;
        margin-left: inherit;
        margin-right: inherit;

        img {
            max-width: 90%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1290";