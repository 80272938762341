.ca-business-data-form {
    padding: $space * 3;
    margin-top: $space * 3;
    background-color: $grey--light;

    .fa-info {
        position: relative;
        display: flex;
        width: 24px;
        min-width: 24px;
        height: 24px;
        margin-right: ($space * 2);
        align-items: center;
        justify-content: center;
        background-color: $theme-color;
        border-radius: 24px;
        color: #fff;
    }
}

;@import "sass-embedded-legacy-load-done:605";