/* ------------------------------------ *\
    TABS
\* ------------------------------------ */

.tabs { @include border($where: 'bottom'); }

.tab {
    position: relative;
    padding: $space ($space * 4);
    background-color: #fff;
    font-size: $font-size--regular-plus;
    border: 0;
    @include border($where: 'bottom', $width: 3px, $color: $theme-color);

    &:hover { color: $theme-color; }

    &.collapsed { border-color: transparent; }

    &::before {
        content: "";
        position: absolute;
        top: 7px;
        left: 0;
        bottom: 7px;
        width: 1px;
        background-color: $border-default-color;
    }

    &:first-child::before { display: none; }
}

.tab__content.collapsed { display: none; }

;@import "sass-embedded-legacy-load-done:913";