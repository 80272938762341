.o-campaign-slider-content {
    width: 100%;
    flex-shrink: 0;
    user-select: none;
    position: relative;

    //a fix for touch events which are not raised in ios devices from the second slide onward
    &::before {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    &__sticky-tl {
        position: absolute;
        top: $space * 4;
        left: 0;
    }

    &__sticky-tr {
        position: absolute;
        top: $space * 4;
        right: 0;
    }

    &__sticky-bl {
        position: absolute;
        bottom: $space * 4;
        left: 0;
    }

    &__sticky-br {
        position: absolute;
        bottom: $space * 4;
        right: 0;
    }

    &__sticky-eyecatcher {
        position: absolute;
        top: $space * 4;
        right: 0;
    }

    &__top-container {
        position: relative;
        margin-bottom: $space * 6;
    }

    &__brand-logo-wrapper {
        padding: ($space * 2) ($space * 4);
        background-color: white;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40%;
    }

    &__brand-logo {
        max-height: 100%;
        object-fit: contain;
    }

    &__banner-img {
        aspect-ratio: 3/4;
        width: 100%;
        object-fit: cover;
        user-select: none;
    }

    &__remaining-days {
        @include text-variant(medium-regular);
        margin-bottom: $space;
        text-transform: uppercase;
        user-select: none;
    }

    &__campaign-title {
        @include text-variant(extra);
        margin-bottom: $space;
        padding-bottom: $space;
        @include line-clamp(2);
        user-select: none;
    }
}


;@import "sass-embedded-legacy-load-done:1144";