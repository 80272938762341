/* ------------------------------------ *\
    CUSTOMER ACCOUNT HEADER
\* ------------------------------------ */

.ca-header {
    display: flex;
    color: $grey--darker;
    flex-direction: column;
    justify-content: center;
    padding: ($space * 2);

    .container {
        display: flex;
        flex-wrap: wrap;
    }

    &__heading {
        font-family: $font-family--main;
        font-size: $font-size--large;
        line-height: 1em;
        padding-bottom: $space;
    }

    h3 {
        font-size: $font-size--medium;
        font-weight: $font-weight--bold;
        line-height: 1em;
        padding-bottom: 8px;
    }

    &--overview {
        display: none;
    }
}

@include media(lg) {
    .ca-header {
        text-align: left;
        margin-top: 0;
        padding: ($space * 4) 0;

        &__heading {
            font-size: $font-size--xlarge;
        }

        &--overview {
            display: flex;
        }
    }
}

;@import "sass-embedded-legacy-load-done:636";