@include media(xxl) {
    .hero-banner {
        &__eyecatcher-container {
            right: 240px;
        }

        &__content--container {
            padding-left: $space * 4;
        }

        &__full-width {
            .brandpage__banner {
                aspect-ratio: 3 / 1;
            }
        }
    }

    .hero-banner-v2 {
        padding: 0 ($space * 4);

        &__text-container {
            width: 50%;
        }

        &__image {
            width: 50%;

            &--ratio {
                aspect-ratio: 16/9;
                width: 605px;
                height: 340px;
            }

            &--primary {
                display: none;
            }

            &--secondary {
                display: block;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1001";