button,
select {
    &.disabled-indicator:not([disabled]),
    .disabled-indicator &:not([disabled]) {
        background-color: $yellow;
        background-image: none;
        color: #fff;
    }
}

;@import "sass-embedded-legacy-load-done:875";