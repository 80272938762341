/* ------------------------------------ *\
    DEFAULT MENU
\* ------------------------------------ */

.menu {
    background-color: #fff;
    z-index: $z1;

    &__list.collapsed {
        max-height: 0;
    }

    &__list-item {
        position: relative;

        a {
            color: $base-text-color;
            display: flex;
            font-size: $font-size--regular;
            font-weight: $font-weight--base;
            padding: ($space * 2) 0;
            width: 100%;
            align-items: center;
            padding-left: 17px; //TODO: Check this with UX to convert to 15px

            svg {
                margin-right: 17px; //TODO: Check this with UX to convert to 15px
            }
        }

        [class^="icon--"],
        .fa {
            align-items: center;
            color: $grey--dark;
            display: flex;
            justify-content: center;
            width: 40px;
        }

        [class^="icon--"] {
            font-size: 18px;
        }

        .fa {
            font-size: 15px;
        }

        [class^="icon--"]:last-child,
        .fa-chevron-right,
        .fa-spinner {
            margin-left: auto;
            font-size: 16px;
        }

        img:first-child {
            display: none;
            max-width: 34px;
            max-height: 34px;
            min-width: 34px;
            min-height: 34px;
            margin-left: 16px;
            margin-right: 12px;
        }

        .co {
            line-height: 0.6;
        }

        &.active {
            a {
                color: $blue;
            }
            svg path {
                fill: $blue;
            }

            [class^="icon--"],
            .fa {
                display: flex;
                color: $blue;
            }
        }

        a:hover {
            text-decoration: none;

            svg path {
                fill: $blue;
            }

            [class^="icon--"],
            .fa {
                color: $blue;
            }
        }

        &::after {
            @include border(bottom, $color: $grey--light);
            bottom: 0;
            content: "";
            left: 42px;
            position: absolute;
            right: 0;
            z-index: $z1;
        }

        &--separator::after {
            @include border(bottom, $color: $grey);
        }

        &--full-separator::after {
            @include border(bottom, $color: $grey--light);
            left: 0 !important;
        }

        &--with-image {
            padding-left: $space * 3;

            &::after {
                left: $space * 3;
            }
        }

        &--title {
            text-transform: uppercase;
            padding-left: $space * 3;
        }

        &--title::after {
            left: 0;
        }

        &--back {
            background-color: $grey--lighter;
        }

        &--back .fa {
            font-size: 16px;
        }

        &--back::after {
            border-color: $grey;
            left: 0;
        }
    }

    &__list-item-name {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    @include media(lg) {
        &__list.collapsed {
            max-height: unset;
        }

        &__list-item {
            [class^="icon--"]:last-child,
            .fa-spinner,
            .fa-chevron-right {
                margin-left: auto;
                display: none;
            }

            img:first-child {
                display: block;
            }

            &--with-image {
                padding-left: 0;
            }

            &--with-image::after {
                left: 64px;
            }

            &.active [class^="icon--"]:last-child,
            &.active .fa-chevron-right,
            &:hover [class^="icon--"]:last-child,
            &:hover .fa-chevron-right {
                display: flex;
                color: $blue;
            }
        }
    }
}


;@import "sass-embedded-legacy-load-done:343";