/* ------------------------------------ *\
    MODAL BOX
\* ------------------------------------ */

.t-modal {
    &__overlay {
        align-items: center;
        background: rgba(#000, 0.3);
        bottom: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        left: 0;
        opacity: 1;
        position: fixed;
        right: 0;
        top: 0;
        z-index: $z-modal;

        &--top-priority {
            z-index: $z-max;
        }

        [data-close] * {
            pointer-events: none;
        }
    }
    &__content {
        background: #fff;
    }

    &__content--regular {
        box-shadow: $shadow3;
        cursor: default;
        max-height: calc(100% - 30px);
        max-width: 650px;
        overflow-y: auto;
        width: calc(100% - 30px);
        z-index: $z2;
    }
}

.modal__overlay--animated-forward {
    animation: modalOverlayOpen 0.3s forwards;

    .t-modal__content {
        animation: modalBodyOpen 0.3s forwards;
    }
}

.modal__overlay--animated-reverse {
    animation: modalOverlayClose 0.3s forwards;

    .t-modal__content {
        animation: modalBodyClose 0.3s forwards;
    }
}

@include animFade(modalOverlayOpen, 0, 1);
@include animFadeAndTranslate(modalBodyOpen, -70, 0, 0, 1);
@include animFade(modalOverlayClose, 1, 0, true);
@include animFadeAndTranslate(modalBodyClose, 0, -70, 1, 0, true);

;@import "sass-embedded-legacy-load-done:1444";