.order-history-search-bar {
    display: flex;
    flex-direction: row;
    margin-top: $space * 4;
    justify-content: space-between;

    &__search {
        width: 100%;
        position: relative;

        &__input {
            text-indent: $space * 5;
            background-position: ($space * 2) 12px;
            background-image: url(/images/svg/header/search-darkgrey-icon.svg);
        }
    }

    &__clear {
        position: absolute;
        right: $space * 3;
        top: 50%;
        transform: translateY(-50%);
        color: $grey--darker;
        opacity: 40%;
        font-size: $font-size--medium;
        cursor: pointer;
    }
}

;@import "sass-embedded-legacy-load-done:732";