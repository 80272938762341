.o-cart-voucher-box {
    margin-top: $space * 8;
    box-shadow: $shadow6;
    padding: $space * 6;
    background: #fff;

    &__header {
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &.collapsed .fa::before {
            content: $fa-var-angle-down;
        }

        &-icon {
            font-size: 28px;
            color: $blue;
        }
    }


    &__content {
        margin-top: $space * 4;
        display: flex;
        flex-wrap: wrap;

        &-wrapper.collapsed {
            max-height: 0;
            overflow: hidden;
        }
    }

    &__btn {
        flex-basis: 100%;
        margin-top: $space * 4;

        .button__text {
            font-size: $font-size--regular-plus;
        }
    }

    &__input-wrapper {
        position: relative;
        flex-grow: 1;
        margin-right: 0;
    }

    &__guest-voucher {
        margin-top: $space * 4;
    }
}

;@import "sass-embedded-legacy-load-done:839";