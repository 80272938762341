//Refactors: styles/v1/components/_product-widget.scss

/* ------------------------------------ *\
    PRODUCT Image with Badges
\* ------------------------------------ */

@include media(xl) {
    .m-product-image {
        .layout-list & {
            width: percentage(calc(2 / 24));
        }
    }
}

;@import "sass-embedded-legacy-load-done:1015";