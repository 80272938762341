@include media(xxl) {
    .o-campaign-slider {
        &--container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &--container-inner {
            max-width: calc(100vw - 140px);
        }

        &__title {
            width: 100%;
        }

        &-content {
            width: calc((100% - 80px) / 3);
            margin-right: $space * 8;

            &__sticky-tl,
            &__sticky-tr {
                top: $space * 8;
            }

            &__sticky-bl,
            &__sticky-br {
                bottom: $space * 8;
            }

            &__sticky-eyecatcher {
                right: $space * 8;
            }

            &__top-container {
                margin-bottom: $space * 8;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1150";