.product-list {
    &__content {
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: ($space * 2) 0;
        flex: 1 1 auto;
    }

    &__inner-content {
        min-width: 200px;
    }

    &__item {
        @include border('bottom');
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
            border: none;
        }
    }

    &__item-image {
        flex: none;
        margin-right: $space * 2;
        width: 80px;
        height: 80px;
    }

    &__item-description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        flex: 1 1 auto;

        h6 {
            @include truncate-text;
            color: $grey--dark;
        }
    }

    &__price {
        margin: 0 ($space * 2);
        min-width: 100px;
        max-width: 100px;
    }

    &__add-to-cart {
        flex: 0 1 auto;
        width: 100%;
    }
}

@include media(md) {
    .product-list {
        &__content {
            flex-direction: row;
            align-items: center;
        }

        &__item-heading {
            flex-grow: 1;
        }
    }
}

@include media(lg) {
    .product-list {
        &__add-to-cart {
            max-width: 175px;
            min-width: 175px;
        }

        &__item:last-child {
            @include border('bottom');
        }

        &__item-heading {
            max-width: 250px;
        }
    }
}

@include media(xl) {
    .product-list {
        &__inner-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            min-width: 200px;
        }

        &__add-to-cart {
            max-width: 200px;
            min-width: 200px;
        }
    }
}

@include media(xxl) {
    .product-list {
        &__content h4 {
            max-width: 500px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:457";