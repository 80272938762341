.m-product-bulk-prices {
    margin: ($space * 4) 0;

    &__accordion {
        width: max-content;

        &.collapsed .fa::before {
            content: $fa-var-angle-down;
        }

        &-title {
            cursor: pointer;
        }

        &-icon {
            cursor: pointer;
            font-size: 28px;
            color: $blue;
            margin-left: $space * 2;
        }
    }

    &__content {
        font-size: $font-size--regular-plus;
        max-height: 400px;
        overflow: hidden;
        transition: max-height 0.5s ease;
        display: flex;
        flex-direction: column-reverse;

        &-title {
            margin-bottom: $space;
        }
        &-item {
            padding-top: $space * 2;
        }
    }

    &__content.collapsed {
        max-height: 0;
        overflow: hidden;
    }

    &__collapse {
        margin: $space * 2 0 $space * 3 0;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            color: $blue;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1210";