.m-bundle-label {
    display: flex;
    align-items: center;
    margin-bottom: $space * 2;

    &__text {
        margin-left: $space;
        white-space: nowrap;
    }
}

;@import "sass-embedded-legacy-load-done:975";