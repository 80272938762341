.m-menu-header {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;

    &__close-button {
        background-color: white;
        position: fixed;
        top: 0;
        width: 100%;
    }

    &__back-button {
        height: 50px;
        width: 100%;
        background-color: white;

        .button--back {
            float: right;
            height: 40px;

            & .fa {
                color: $grey--dark;
                margin-top: 0;
            }

            & .fa::before {
                font-size: $font-size--regular-plus;
            }
        }
    }

    .m-menu-item__headline {
        padding: ($space * 2) ($space * 4) ($space * 6) ($space * 3);
    }
}

;@import "sass-embedded-legacy-load-done:1005";