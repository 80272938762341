@include media(md) {
    .m-product-list-empty {
        row-gap: 4 * $space;

        &__action-button {
            width: fit-content;
            margin-top: 2 * $space;
        }
    }

}

;@import "sass-embedded-legacy-load-done:1019";