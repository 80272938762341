.m-pictures-menu {
    width: calc(100vw - 40px);
    padding-top: $space * 16;
    padding-bottom: $space * 6;
    background-color: white;
    overflow: hidden;

    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 ($space * 3) ($space * 8);
    }

    &__item {
        margin-left: $space * 3;
        margin-right: $space * 3;
        width: 142px;
        height: 85px;
    }

    &__button {
        margin: 0 auto;
    }
}

;@import "sass-embedded-legacy-load-done:1009";