/* Headline classes */
@include generate-breakpoint-prefixes($class-name: '_a-h', $short-name: true) {
    &1 {
        font-size: $font-size--xlarge;
        line-height: $line-height--xlarge;

        /* Extra large h1 (mostly for CMS pages) */
        &--res {
            font-size: 36px;

            @include media(md) {
                font-size: 48px;
                line-height: 48px;
            }

            @include media(lg) {
                font-size: 60px;
                line-height: 60px;

            }
        }

        &--main {
            font-family: $font-family--main;
            font-size: $font-size--xlarge;
            font-weight: $font-weight--base;
        }
    }

    &2 {
        font-size: $font-size--large;
        line-height: $line-height--large;

        /* Extra large h2 (mostly for CMS pages) */
        &--res {
            font-size: $font-size--regular-plus;

            @include media(md) {
                font-size: $font-size--medium;
            }

            @include media(xl) {
                font-size: $font-size--large;
            }
        }

        &--main {
            font-family: $font-family--main;
            font-size: $font-size--large;
            font-weight: $font-weight--base;
        }
    }

    &3 {
        font-size: $font-size--medium;
        line-height: $line-height--regular;

        &--main {
            font-family: $font-family--main;
            font-size: $font-size--medium;
            font-weight: $font-weight--base;
        }
    }

    &4 {
        font-size: $font-size--regular-plus;
        line-height: $line-height--regular;

        &--main {
            font-family: $font-family--main;
            font-size: $font-size--regular-plus;
            font-weight: $font-weight--base;
        }
    }

    &5 {
        font-size: $font-size--regular;
        line-height: $line-height--regular;

        &--main {
            font-family: $font-family--main;
            font-size: $font-size--regular;
            font-weight: $font-weight--base;
        }
    }

    &6 {
        font-size: $font-size--small;
        line-height: $line-height--small;

        &--main {
            font-family: $font-family--main;
            font-size: $font-size--regular;
            font-weight: $font-weight--base;
        }
    }
}

/* Headline tags */
h1 {
    @extend ._a-h1;
}

h2 {
    @extend ._a-h2;
}

h3 {
    @extend ._a-h3;
}

h4 {
    @extend ._a-h4;
}

h5 {
    @extend ._a-h5;
}

h6 {
    @extend ._a-h6;
}

h1.txt--main,
h2.txt--main,
h3.txt--main,
h4.txt--main,
h5.txt--main,
h6.txt--main,
._a-h1.txt--main,
._a-h2.txt--main,
._a-h3.txt--main,
._a-h4.txt--main,
._a-h5.txt--main,
._a-h6.txt--main {
    font-weight: $font-weight--base !important;
}


/* Raw HTML */
.html--raw {
    h2,
    h3 {
        font-weight: $font-weight--bold;

        &.txt--main {
            font-weight: $font-weight--base;
        }
    }

    h1 {
        @extend ._a-h2;
        font-family: $font-family--main;
        font-weight: $font-weight--base;
        margin: ($space * 2) 0;
    }

    h2 {
        margin: ($space * 2) 0;
    }

    h3 {
        margin: $space 0;
    }
}

;@import "sass-embedded-legacy-load-done:1160";