.o-product-tech-data-table {
    margin-bottom: $space * 3;

    table {
        td {
            width: 50%;
        }
    }

    &__title {
        padding: $space ($space * 2) ($space * 3) ($space * 2);
    }
}

;@import "sass-embedded-legacy-load-done:1389";