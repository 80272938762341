.o-product-detail {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: $space * 3;

    &:not(:last-child) {
        border-bottom: 1px solid $grey--medium;
    }

    &__price {
        margin-left: auto;
        margin-right: 0;
    }

    &__image {
        min-height: 68px;
        min-width: 68px;
        max-width: 68px;
        margin-right: $space * 2;
    }

    &__title {
        margin-bottom: $space * 2;
        word-break: break-word;
    }

    &__sku {
        margin-top: $space * 2;
        margin-bottom: $space * 2;
    }

    &__quantity {
        margin-bottom: $space * 2;
    }
}

;@import "sass-embedded-legacy-load-done:1367";