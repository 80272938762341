.feedback {
    display: flex;
    flex-wrap: wrap;

    &__section {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @include media(lg) {
            width: percentage(calc(1 / 3));
            margin-top: 30px;
        }
    }

    &__img {
        margin: 15px auto;
        width: 100%;
        height: 100px;
        background: transparent none center no-repeat;

        @include media(lg) {
            margin-bottom: 30px;
        }
    }

    &__button {
        margin: 0 auto 30px;
        min-width: 190px;
        max-width: 220px;
        height: 33px;

        @include media(lg) {
            margin-bottom: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:798";