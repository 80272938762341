@include media(xl) {
    .t-product-highlight {
        padding-bottom: ($space * 4);

        &__wrapper {
            margin-bottom: $space * 20;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1393";