.m-item-info {
    &__bundle-badge {
        display: inline-block;
        margin-bottom: $space * 2;
    }

    &__title-link {
        display: block;
        margin-bottom: $space * 2;
    }

    &__title {
        margin-bottom: $space * 2;
        word-break: break-word;
    }

    &__sku {
        margin-bottom: $space * 4;
    }

    &__package-size {
        margin-bottom: $space*2;
    }

    &__delivery {
        margin-top: $space * 4;
        margin-bottom: $space * 4;

        &-express {
            margin-bottom: $space * 2;
        }
    }

    &__express-delivery {
        padding-left: $space * 5;
        position: relative;

        &::before {
            content: $contorion-icon--express-delivery;
            font-family: $contorion-font-family;
            font-size: $font-size--medium-plus;
            position: absolute;
            left: 0;
        }
    }

    &__details:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

;@import "sass-embedded-legacy-load-done:828";