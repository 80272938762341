.sub-footer {
    background: $grey--darker;
    margin-top: $space * 2;

    &__wrapper {
        display: flex;
        flex-direction: column;
        padding: $space * 3 0 $space * 2 0;
        color: $grey;
    }

    &__customer-switch {
        text-align: center;
    }

    &__links {
        text-align: center;
        margin-top: $space * 2;
    }

    &__link {
        font-size: $font-size--regular-plus;
        line-height: $line-height--regular;
        padding: $space * 2 0;
        margin: 0 $space;
        cursor: pointer;
        font-weight: bold;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1414";