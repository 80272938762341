@include media(md) {
    .o-featured-product {
        flex-wrap: wrap;
        width: auto;

        &__image {
            height: 459px;
        }

        &__content {
            padding: $space * 6;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1336";